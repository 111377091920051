import { SettingsManager } from '@classes/settingsManager';
import RenderIf from '@common/RenderIf';
import { rootStore } from '@store/rootStore/instanse';
import { useReactive } from 'ahooks';
import { useEffect, useRef, useState } from 'react';
import useDraggableScroll from 'use-draggable-scroll';


type TProps = {
    items: any[],
    mosesSpread?: boolean,
};

export default function AppFooter({ items, mosesSpread = false }: TProps) {
    const navRef = useRef<HTMLDivElement>(null);
    const secondaryNavRef = useRef<HTMLDivElement>(null);
    const { onMouseDown } = useDraggableScroll(navRef, {
        direction: 'horizontal',
    });
    const [elementWidth, setElementWidth] = useState<string>('');
    const creds = SettingsManager.getConnectionCredentials();
    const state = useReactive({
        kbOpen: false
    })

    /**
     * @handleResize функция Измененте ширины элементов меню
     * navRef.current.clientWidth общая ширина текущего меню в зависимости от ширины экрана
     * 31 - сумма гэпов между элементами и паддингов
     * (navWidth -31)/7 ширина одного элемента, которую нужно отнять от общей ширины, чтобы получилось так, что последний элемент виден на половину
     */
    function handleResize() {
        state.kbOpen = window.screen.height - 200 > window.visualViewport.height;
        setElementWidth(`${((navRef?.current?.clientWidth - (navRef?.current?.clientWidth - 31) / 7) / 7).toString()}px`);
    }

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        if (navRef.current === null) return;
        const dragElement = navRef.current.getBoundingClientRect();
        if (dragElement.left < 0) {
            navRef.current.scrollBy(-10, 0);
        }
        if (dragElement.right > window.innerWidth) {
            navRef.current.scrollBy(10, 0);
        }
    }, [navRef]);

    return (
        <div id="apelsin-app-footer" style={{display: state.kbOpen ? "none" : "block"}}>
            <RenderIf condition={creds?.crmID != null && creds?.crmName != null}>
                <div className='footer-crm-name'>{creds?.crmName}</div>
            </RenderIf>
            <div className='nav-container'>
                <div
                    ref={navRef}
                    onMouseDown={onMouseDown}
                    className="nav-menu"
                    style={
                        items.length == 3
                            ? { justifyContent: 'center' }
                            : { justifyContent: 'space-between' }
                    }
                >
                    <RenderIf condition={mosesSpread}>
                        <div className='cringe-meter'></div>
                    </RenderIf>
                    {(mosesSpread 
                        ? items.filter(item => !item.key.toString().includes('personal-'))
                        : items
                    ).map((item) => {
                        return (
                            item.key == 'divider' 
                            ? (<div key={item.key} className='menu-content'>
                                <div className='menu-item-divider'></div>
                            </div>)
                            : (<div key={item.key} className="menu-content" onClick={item.disabled ? null : item.onClick}>
                                <div
                                    className={
                                        `${item.disabled ? 'menu-item-disabled' : 'menu-item'}`
                                        +`${item.key.includes('personal-') ? ' menu-item-personal' : ''}`
                                        +`${rootStore.navigationStore.checkForCurrent(item.key) 
                                            ? ' menu-item-current' 
                                            : rootStore.navigationStore.ifStored(item.key) ? ' menu-item-stored' : ''}`
                                    }
                                    style={{ width: elementWidth }}
                                >
                                    {item.icon}
                                </div>
                                <span className="menu-text">{item.title}</span>
                            </div>)
                        );
                    })}
                </div>
                <RenderIf condition={mosesSpread}>
                    <div
                        className="nav-menu-secondary"
                        style={
                            items.length == 3
                                ? { justifyContent: 'center' }
                                : { justifyContent: 'space-between' }
                        }
                    >
                        {items.filter(item => item.key.toString().includes('personal-')).map((item) => {
                            return (
                                item.key == 'personal-divider' 
                                ? (<div key={item.key} className='menu-content'>
                                    <div className='menu-item-divider'></div>
                                </div>)
                                : (<div key={item.key} className="menu-content" onClick={item.disabled ? null : item.onClick}>
                                    <div
                                        className={`${item.disabled ? 'menu-item-disabled' : 'menu-item'}${item.key.includes('personal-') ? ' menu-item-personal' : ''}${rootStore.navigationStore.checkForCurrent(item.key) ? ' menu-item-current' : ''}`}
                                        style={{ width: elementWidth }}
                                    >
                                        {item.icon}
                                    </div>
                                    <span className="menu-text">{item.title}</span>
                                </div>)
                            );
                        })}
                    </div>
                </RenderIf>
            </div>
        </div>
    );
}
