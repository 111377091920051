import { TOrderStatus, TOrderStatusType } from "types/Orders/status";

const StatusTypes: TOrderStatusType[] = [
    {
        id: 0,
        order: 1,
        name: 'На согласовании',
        color: '#0000A0',
    },
    {
        id: 1,
        order: 2,
        name: 'Новый заказ',
        color: '#FFFF00',
    },
    {
        id: 2,
        order: 3,
        name: 'Задержка старта',
        color: '#DD0000',
    },
    {
        id: 3,
        order: 4,
        name: 'В работе',
        color: '#FF8000',
    },
    {
        id: 4,
        order: 5,
        name: 'Ожидает',
        color: '#B8AD04',
    },
    {
        id: 5,
        order: 6,
        name: 'Ожидает оплаты',
        color: '#008040',
    },
    {
        id: 6,
        order: 7,
        name: 'Завершён',
        color: '#1649FA',
    },
    {
        id: 7,
        order: 8,
        name: 'Отменён',
        color: '#D242A0',
    }
];

const Statuses: TOrderStatus[] = [

];

export { StatusTypes, Statuses };