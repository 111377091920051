import { findIndex, includes, some, sumBy, without } from 'lodash';
import { TOrder } from 'types/Orders/order';

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { EmptyRows, initialOrderData, withoutExecutorFields } from '../constants';

const initialState = {
    data: initialOrderData,
    totalProducts: 0,
    totalServices: 0,
    selectedServices: [],
    selectedProducts: [],
    selectedWorksheet: null,
    immutableOrderData: null,
    orderObjects: [],
    mutableOrderFields: [],
    immutableOrderFields: [],
    mutableRequiredOrderFields: [],
    immutableRequiredOrderFields: [],
    updatedRowIndex: null,
    selectedItemsFromSearchTabs: {
        products: [],
        services: [],
        combo: [],
        hammer: [],
    },

    // Временно
    selectedItemsWithoutExecutor: [],
    totalItemsWithoutExecutor: 0,
};

const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        setOrderData: (state, action: PayloadAction<TOrder>) => {
            const { payload } = action;
            state.data = payload;

            state.totalProducts = sumBy(state.data.products, 'total');
            state.totalServices = sumBy(state.data.services, 'total');
            state.totalItemsWithoutExecutor = sumBy(state.data.withoutExecutorFields, 'total');
        },

        // Управление продуктами
        setProducts: (state, { payload }) => {
            state.data.products = payload;
            state.totalProducts = sumBy(state.data.products, 'total');
        },

        selectProduct: (state, { payload }) => {
            if (!includes(state.selectedProducts, payload)) {
                state.selectedProducts.push(payload);
                return;
            }
            state.selectedProducts = without(state.selectedProducts, payload);
        },

        deleteSelectedProducts: (state) => {
            state.data.products = state.data.products.filter(
                (item) => !state.selectedProducts.includes(item.id)
            );
            state.totalProducts = sumBy(state.data.products, 'total');
            state.selectedProducts = [];
        },

        selectAllProducts: (state) => {
            if (state.selectedProducts.length !== state.data.products.length - 2) {
                state.selectedProducts = state.data.products
                    .map((item) => item.id)
                    .filter((id) => id !== EmptyRows.EMPTY_ROW_1 && id !== EmptyRows.EMPTY_ROW_2);
                return;
            }
            state.selectedProducts = [];
        },

        clearSelectedProducts: (state) => {
            state.selectedProducts = [];
        },

        // Управление услугами
        setServices: (state, { payload }) => {
            state.data.services = payload;
            state.totalServices = sumBy(state.data.services, 'total');
        },

        selectService: (state, { payload }) => {
            if (!includes(state.selectedServices, payload)) {
                state.selectedServices.push(payload);
                return;
            }
            state.selectedServices = without(state.selectedServices, payload);
        },

        deleteSelectedServices: (state) => {
            state.data.services = state.data.services.filter(
                (item) => !state.selectedServices.includes(item.id)
            );
            state.totalServices = sumBy(state.data.services, 'total');
            state.selectedServices = [];
        },

        selectAllServices: (state) => {
            if (state.selectedServices.length !== state.data.services.length - 2) {
                state.selectedServices = state.data.services
                    .map((item) => item.id)
                    .filter((id) => id !== EmptyRows.EMPTY_ROW_1 && id !== EmptyRows.EMPTY_ROW_2);
                return;
            }
            state.selectedServices = [];
        },

        clearSelectedServices: (state) => {
            state.selectedServices = [];
        },

        setOrderProperty: (state, { payload }) => {
            const { key, value } = payload;
            state[key] = value;
        },

        setMutableOrderFieldValue: (state, { payload }) => {
            const { index, value } = payload;
            state.mutableOrderFields[index].value = value;

            const intersectingFieldIndex = findIndex(
                state.mutableRequiredOrderFields,
                (field) => field.id === state.mutableOrderFields[index].id
            );
            if (
                intersectingFieldIndex !== -1 &&
                !state.mutableRequiredOrderFields[intersectingFieldIndex].isOrderObject
            )
                state.mutableRequiredOrderFields[intersectingFieldIndex].value = value;
        },

        setMutableRequiredOrderFieldValue: (state, { payload }) => {
            const { index, value } = payload;
            state.mutableRequiredOrderFields[index].value = value;

            const intersectingFieldIndex = findIndex(
                state.mutableOrderFields,
                (field) => field.id === state.mutableRequiredOrderFields[index].id
            );
            if (
                intersectingFieldIndex !== -1 &&
                !state.mutableRequiredOrderFields[index].isOrderObject
            )
                state.mutableOrderFields[intersectingFieldIndex].value = value;
        },

        setOrderObject: (state, { payload }) => {
            state.data.order_object = payload;
        },

        setSelectedItemsFromSearchTabs: (state, { payload: { item, key } }) => {
            if (some(state.selectedItemsFromSearchTabs[key], item)) {
                state.selectedItemsFromSearchTabs[key] = state.selectedItemsFromSearchTabs[
                    key
                ].filter((elem) => elem.id !== item.id);
            } else {
                state.selectedItemsFromSearchTabs[key] = [
                    ...state.selectedItemsFromSearchTabs[key],
                    item,
                ];
            }
        },

        resetSelectedItemsFromSearchTabs: (state) => {
            state.selectedItemsFromSearchTabs = {
                products: [],
                services: [],
                combo: [],
                hammer: [],
            };
        },

        // Временно
        selectItemWithoutExecutor: (state, { payload }) => {
            if (!includes(state.selectedItemsWithoutExecutor, payload)) {
                state.selectedItemsWithoutExecutor.push(payload);
                return;
            }
            state.selectedItemsWithoutExecutor = without(
                state.selectedItemsWithoutExecutor,
                payload
            );
        },

        selectAllItemsWithoutExecutor: (state) => {
            if (
                state.selectedItemsWithoutExecutor.length !==
                state.data.withoutExecutorFields.length - 2
            ) {
                state.selectedItemsWithoutExecutor = state.data.withoutExecutorFields
                    .map((item) => item.id)
                    .filter((id) => id !== EmptyRows.EMPTY_ROW_1 && id !== EmptyRows.EMPTY_ROW_2);
                return;
            }
            state.selectedItemsWithoutExecutor = [];
        },

        clearSelectedItemsWithoutExecutor: (state) => {
            state.selectedItemsWithoutExecutor = [];
        },
        //

        resetOrderState: (state) => {
            state.data = initialOrderData;
            state.totalProducts = 0;
            state.totalServices = 0;
            state.selectedServices = [];
            state.selectedProducts = [];
            state.selectedWorksheet = null;
            state.immutableOrderData = null;
            state.orderObjects = [];
            state.mutableOrderFields = [];
            state.immutableOrderFields = [];
            state.mutableRequiredOrderFields = [];
            state.immutableRequiredOrderFields = [];
            state.selectedItemsFromSearchTabs = {
                products: [],
                services: [],
                combo: [],
                hammer: [],
            };
        },
    },
});

export const {
    setOrderData,
    clearSelectedProducts,
    deleteSelectedProducts,
    selectAllProducts,
    selectProduct,
    setProducts,
    clearSelectedServices,
    deleteSelectedServices,
    selectAllServices,
    selectService,
    setServices,
    setOrderProperty,
    setMutableOrderFieldValue,
    setMutableRequiredOrderFieldValue,
    resetOrderState,
    setOrderObject,
    selectAllItemsWithoutExecutor,
    selectItemWithoutExecutor,
    clearSelectedItemsWithoutExecutor,
    setSelectedItemsFromSearchTabs,
    resetSelectedItemsFromSearchTabs,
} = orderSlice.actions;
export default orderSlice.reducer;
