import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { userEvents } from '../data';

const usersSlice = createSlice({
    name: 'users',
    initialState: {
        data: {
            events: userEvents,
        },
        isMyInformationVisible: false,
    },
    reducers: {
        setUserData: (state, action: PayloadAction<any>) => {
            state.data = {
                ...action.payload,
                events: userEvents,
            };
        },

        setIsMyInformationVisible: (state, action: PayloadAction<boolean>) => {
            state.isMyInformationVisible = action.payload;
        },
    },
});

export default usersSlice.reducer;

export const { setUserData, setIsMyInformationVisible } = usersSlice.actions;
