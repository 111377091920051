import { TOrderStatus, TOrderStatusType } from "types/Orders/status";
import { Statuses, StatusTypes } from "./data";
import { makeAutoObservable } from "mobx";

class OrderStatusStore {
    private statusTypes: TOrderStatusType[] = [];
    private statuses: TOrderStatus[] = [];

    constructor() {
        makeAutoObservable(this);
        this.statusTypes = [...StatusTypes];
        this.statuses = [...Statuses];
    }

    // Status Types
    statusTypeList() {
        return [...this.statusTypes].sort((a, b) => a.order-b.order);
    }

    getStatusType(id: number) {
        return this.statusTypes.find(st => st.id == id);
    }

    createStatusType(statusType: TOrderStatusType) {
        let newId = 0;
        if (this.statusTypes.length > 0) {
            for (let i = 0; i < this.statusTypes.length; i++) {
                if (this.statusTypes[i].id >= newId) newId = this.statusTypes[i].id + 1;
            }
        }
        const newST = { ...statusType, id: newId };
        this.statusTypes.push(newST);
        return newST;
    }

    updateStatusType(statusType: TOrderStatusType) {
        let toUpdate = this.statusTypes.find(st => st.id == statusType.id);
        if (toUpdate != undefined) {
            this.statusTypes = this.statusTypes.map(st => {
                if (st.id == statusType.id) {
                    let tempST = {
                        ...st,
                        ...statusType
                    };
                    toUpdate = tempST;
                    return tempST;
                } else {
                    return st;
                }
            });
            return toUpdate;
        }
        return null;
    }

    removeStatusType(id: number) {
        //CHECK IF USED
        this.statusTypes = this.statusTypes.filter(st => st.id != id);
    }
    // STATUSES
    statusList() {
        return this.statuses;
    }

    getStatus(crm_id: number, order_id: number) {
        return this.statuses.find(s => s.crm_id == crm_id && s.order_id == order_id);
    }

    statusToString(status: TOrderStatus) {
        const st = this.getStatusType(status.status_id);
        let str = st.name;
        if (status.relation_type) {
            switch (status.relation_type) {
                case "orderProduct":
                    str += " товар: ";
                    break;
                case "orderService":
                    str += " услуга: ";
                    break;
                case "orderTask":
                    str += " задача: ";
                    break;
            }
        }
        return str;
    }

    setStatus(status: TOrderStatus) {
        let toUpdate = this.statuses.find(st => st.crm_id == status.crm_id && st.order_id == status.order_id);
        if (toUpdate != undefined) {
            this.statuses.map(st => {
                if (st.crm_id == status.crm_id && st.order_id == status.order_id) {
                    let tempST = {
                        ...st,
                        ...status
                    };
                    toUpdate = tempST;
                    return tempST;
                } else {
                    return st;
                }
            });
            return toUpdate;
        } else {
            this.statuses.push(status);
            return status;
        }
    }
}

export { OrderStatusStore };