import { get, has, keys, makeAutoObservable, set } from "mobx";
import { useLocation } from "react-router-dom";

class NavigationStore {
    public routes: { 
        [k: string]: { 
            whitelist: string[], 
            blacklist?: string[],
            lastPath?: string,
        } 
    } = {
        "login": {
            whitelist: ['/login'],
        },
        "personal-profile": {
            whitelist: ['/lk'],
            blacklist: [
                '/worker',
            ],
        },
        "personal-settings": {
            whitelist: ['/settings'],
        },
        "crm": {
            whitelist: ['/crm'],
            blacklist: [
                '/invitations',
                '/workers',
                '/users',
                '/customers',
                '/specialties',
                '/product-category',
                '/products',
                '/service-categories',
                '/services',
                '/task-categories',
                '/tasks',
                '/orders',
                '/logs',
                '/transactions',
                '/settings'
            ],
        },
        "workers": {
            whitelist: ['/workers'],
            lastPath: null
        },
        "customers": {
            whitelist: ['/customers'],
            lastPath: null
        },
        "orders": {
            whitelist: ['/orders'],
            lastPath: null
        },
        "tasks": {
            whitelist: ['/tasks'],
            lastPath: null
        },
        "services": {
            whitelist: ['/services', '/service-categories'],
            lastPath: null
        },
        "products": {
            whitelist: ['/products', '/product-categories'],
            lastPath: null
        },
        "transactions": {
            whitelist: ['/transactions']
        },
        "logs": {
            whitelist: ['/logs']
        },
        "workplace": {
            whitelist: ['/lk/worker/profile', '/users'],
            lastPath: null
        }
    };

    constructor() {
        makeAutoObservable(this);
    }

    checkForCurrent(k: string) {
        const location = useLocation();
        if (!keys(this.routes).includes(k)) {
            return false;
        }
        const route = get(this.routes, k);
        let decision = false;
        for (let i = 0; i < route.whitelist.length; i++) {
            if (location.pathname.includes(route.whitelist[i])) {
                decision = true;
            }
        }
        if (Object.keys(route).includes("blacklist")) {
            for (let i = 0; i < route.blacklist.length; i++) {
                if (location.pathname.includes(route.blacklist[i])) {
                    decision = false;
                }
            }
        }
        return decision;
    }

    flushLastPath() {
        for (let index in keys(this.routes)) {
            const key = keys(this.routes)[index].toString();
            const route = get(this.routes, key);
            if (Object.keys(route).includes("lastPath")) {
                set(
                    this.routes, 
                    key, 
                    {
                        whitelist: route.whitelist, 
                        blacklist: route.blacklist ?? [], 
                        lastPath: null  
                    }
                );
            }
        }
    }

    ifStored(k: string) {
        return (has(this.routes, k) && get(this.routes, k).lastPath)
    }

    pushLastPath(path: string) {
        for (let i = 0; i < keys(this.routes).length; i++) {
            const key = keys(this.routes)[i].toString();
            const route = get(this.routes, key);
            if (Object.keys(route).includes('lastPath')) {
                let decision = false;
                for (let i = 0; i < route.whitelist.length; i++) {
                    if (path.includes(route.whitelist[i])) {
                        decision = true;
                    }
                }
                if (Object.keys(route).includes('blacklist')) {
                    for (let i = 0; i < route.blacklist.length; i++) {
                        if (path.includes(route.blacklist[i])) {
                            decision = false;
                        }
                    }
                }
                if (decision) {
                    set(
                        this.routes, 
                        key, 
                        { 
                            whitelist: route.whitelist, 
                            blacklist: route.blacklist ?? [], 
                            lastPath: path 
                        }
                    );
                }
            }
        }
    }

    getLastPath(k: string) {
        if (has(this.routes, k) && Object.keys(get(this.routes, k)).includes("lastPath")) {
            return get(this.routes, k).lastPath;
        } else {
            return null;
        }
    }
}

export { NavigationStore };