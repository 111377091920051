import moment from "moment";
import { APRecord } from "./store";

const defaultWorkers = [
    {
        id: 1,
        name: "Механик | Артём Д.",
        color: "#FF0000"
    },
    {
        id: 2,
        name: "Электрик | Андрей Е.",
        color: "#00FF00"
    },
    {
        id: 3,
        name: "Сварщик | Игорь М.",
        color: "#FFFF00"
    }
];

const defaultRecords: APRecord[] = [
    {
        id: 1,
        workerId: 1,
        index: 1,
        type: 'task',
        name: "Двигатель 4-х цилиндровый",
        start: moment(),
        duration: 720,
        amount: 5,
        price: 4236,
    },
    {
        id: 2,
        workerId: 1,
        index: 2,
        type: 'service',
        name: "Токарные работы",
        start: moment().add(720, 'minutes'),
        duration: 1030,
        amount: 10,
        price: 5323,
    },
    {
        id: 3,
        workerId: 1,
        index: 3,
        type: 'service',
        name: "Шаровая опора передняя, замена",
        start: moment().add(720+1030, 'minutes'),
        duration: 120,
        amount: 5,
        price: 6912,
    },
    {
        id: 4,
        workerId: 2,
        index: 4,
        type: 'task',
        name: "Двигатель 4-х цилиндровый",
        start: moment(),
        duration: 720,
        amount: 5,
        price: 4236,
    },
    {
        id: 5,
        workerId: 2,
        index: 5,
        type: 'service',
        name: "Токарные работы",
        start: moment().add(720, 'minutes'),
        duration: 1030,
        amount: 10,
        price: 5323,
    },
    {
        id: 6,
        workerId: 2,
        index: 6,
        type: 'service',
        name: "Шаровая опора передняя, замена",
        start: moment().add(720+1030, 'minutes'),
        duration: 120,
        amount: 5,
        price: 6912,
    },
    {
        id: 7,
        workerId: 3,
        index: 7,
        type: 'task',
        name: "Двигатель 4-х цилиндровый",
        start: moment(),
        duration: 720,
        amount: 5,
        price: 4236,
    },
    {
        id: 8,
        workerId: 3,
        index: 8,
        type: 'service',
        name: "Токарные работы",
        start: moment().add(720, 'minutes'),
        duration: 1030,
        amount: 10,
        price: 5323,
    },
    {
        id: 9,
        workerId: 3,
        index: 9,
        type: 'service',
        name: "Шаровая опора передняя, замена",
        start: moment().add(720+1030, 'minutes'),
        duration: 120,
        amount: 5,
        price: 6912,
    },
];

export { defaultRecords, defaultWorkers };