import { UserEvent } from './types';

export const userEvents: UserEvent[] = [
    { id: 1, title: 'Сходить в парикмахерскую', time: '10:00', company: 'Название компании' },
    { id: 2, title: 'Купить продукты', time: '11:00', company: 'Название компании' },
    { id: 3, title: 'Пойти в спортзал', time: '12:00', company: 'Название компании' },
    { id: 4, title: 'Посетить стоматолога', time: '13:00', company: 'Название компании' },
    { id: 5, title: 'Встретиться с друзьями', time: '14:00', company: 'Название компании' },
    { id: 6, title: 'Позвонить клиенту', time: '15:00', company: 'Название компании' },
    { id: 7, title: 'Написать отчет', time: '16:00', company: 'Название компании' },
    { id: 8, title: 'Сходить на выставку', time: '17:00', company: 'Название компании' },
    { id: 9, title: 'Проверить почту', time: '18:00', company: 'Название компании' },
    {
        id: 10,
        title: 'Пройти медицинское обследование',
        time: '19:00',
        company: 'Название компании',
    },
    { id: 11, title: 'Сходить в кино', time: '20:00', company: 'Название компании' },
    { id: 12, title: 'Забрать заказ', time: '21:00', company: 'Название компании' },
    { id: 13, title: 'Уборка дома', time: '08:00', company: 'Название компании' },
    { id: 14, title: 'Запланировать отпуск', time: '09:00', company: 'Название компании' },
    { id: 15, title: 'Сходить к юристу', time: '10:30', company: 'Название компании' },
    { id: 16, title: 'Пройти курсы', time: '11:30', company: 'Название компании' },
    { id: 17, title: 'Купить подарок', time: '12:30', company: 'Название компании' },
    { id: 18, title: 'Посетить собрание', time: '13:30', company: 'Название компании' },
    { id: 19, title: 'Пойти на прогулку', time: '14:30', company: 'Название компании' },
    { id: 20, title: 'Сходить на концерт', time: '15:30', company: 'Название компании' },
];
