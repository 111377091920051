import { Loader } from "@atoms/loader";
import RenderIf from "@common/RenderIf";
import { GlobalConstants } from "@constants/global";
import { faCheck, faGear, faGripVertical, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FunctionalMenu } from "@molecules/functionalMenu";
import { rootStore } from "@store/rootStore/instanse";
import { useReactive } from "ahooks";
import { Button, Col, Input, message, Table, TableProps } from "antd";
import { observer } from "mobx-react";
import { ChangeEvent, useContext, useEffect, useMemo } from "react";
import { TOrderStatusType } from "types/Orders/status";
import { HeaderBreadcrumbProfiles } from "@molecules/breadcrumbs/profilesBreadcrumbs/HeaderProfilesBreadcrumb";

import "./style.scss";
import { useParams } from "react-router-dom";
import { SettingsManager } from "@classes/settingsManager";
import { ColorPicker, Popconfirm } from "antd-v5";
import { TimerId } from "../common/types";
import React from "react";
import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import { SortableContext, useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { CSS } from '@dnd-kit/utilities';
import { DndContext, DragEndEvent } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";

interface RowContextProps {
    setActivatorNodeRef?: (element: HTMLElement | null) => void;
    listeners?: SyntheticListenerMap;
}
  
const RowContext = React.createContext<RowContextProps>({});
  
const DragHandle: React.FC = () => {
    const { setActivatorNodeRef, listeners } = useContext(RowContext);
    return (
        <Button
            type="text"
            size="small"
            icon={<FontAwesomeIcon icon={faGripVertical} />}
            style={{ cursor: 'move', touchAction: 'none' }}
            ref={setActivatorNodeRef}
            {...listeners}
        />
    );
};

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
    'data-row-key': string;
}

const Row: React.FC<RowProps> = (props) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        setActivatorNodeRef,
        transform,
        transition,
        isDragging
    } = useSortable({ id: props['data-row-key'] });

    const style: React.CSSProperties = {
        ...props.style,
        transform: CSS.Translate.toString(transform),
        transition,
        ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
    };

    const contextValue = useMemo<RowContextProps>(
        () => ({ setActivatorNodeRef, listeners }),
        [setActivatorNodeRef, listeners],
    );

    return (
        <RowContext.Provider value={contextValue}>
            <tr {...props} ref={setNodeRef} style={style} {...attributes} />
        </RowContext.Provider>
    );
};

type TState = {
    isLoading: boolean,
    isFunctionalMenuVisible: boolean,
    searchValue: string,
    isSearching: boolean,
    saveTimeout: NodeJS.Timeout,
    searchTimeout: NodeJS.Timeout,
    statusTypes: TOrderStatusType[],
    changed: number[]
};

const OrderStatusSetting = observer((): JSX.Element => {
    const state = useReactive<TState>({
        isLoading: false,
        isFunctionalMenuVisible: false,
        searchValue: '',
        isSearching: false,
        saveTimeout: null,
        searchTimeout: null,
        statusTypes: rootStore.orderStatusStore.statusTypeList(),
        changed: []
    });
    const params = useParams();
    const sectionName = params?.sectionName;
    const creds = SettingsManager.getConnectionCredentials();
    const [messageApi, contextHolder] = message.useMessage();

    const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
        if (state.searchTimeout) {
            clearTimeout(state.searchTimeout);
            state.searchTimeout = null;
        }

        state.searchTimeout = setTimeout(() => {
            if (e.target.value !== '') {
                state.isSearching = true;
                state.statusTypes = rootStore.orderStatusStore.statusTypeList()
                    .filter(st => st.name.toLowerCase()
                        .includes(e.target.value.toLowerCase()));
                state.isSearching = false;
                return;
            } else {
                state.isSearching = true;
                state.statusTypes = rootStore.orderStatusStore.statusTypeList();
                state.isSearching = false;
            }
        }, GlobalConstants.SearchTimeout);
        state.searchValue = e.target.value;
    };

    const addStatusType = () => {
        rootStore.orderStatusStore.createStatusType({
            id: -1,
            order: state.statusTypes.length + 1,
            name: state.searchValue,
            color: '#FFFFFF',
        });
        state.searchValue = null;
        clearTimeout(state.searchTimeout);
        state.statusTypes = rootStore.orderStatusStore.statusTypeList();
    }

    const handleSave = () => {
        let changed = [...state.changed];
        while (changed.length > 0) {
            const curID = changed.pop();
            const curST = state.statusTypes.find(st => st.id == curID);
            rootStore.orderStatusStore.updateStatusType(curST);
        }
        state.isFunctionalMenuVisible = false;
        state.changed = [];
        state.statusTypes = rootStore.orderStatusStore.statusTypeList();
    }

    const statusTypeChange = (id: number) => {
        if (!state.changed.includes(id)) state.changed.push(id);
    }

    useEffect(() => {
        if (state.changed.length > 0) {
            state.isFunctionalMenuVisible = true;
            if (state.saveTimeout) clearTimeout(state.saveTimeout);
            const timerId: TimerId = setTimeout(() => {
                handleSave();
            }, 5000);
            state.saveTimeout = timerId;
        }
    }, [state.changed.length]);

    const columns: TableProps<TOrderStatusType>['columns'] = [
        {
            key: 'sort',
            align: 'center',
            width: 50,
            render: () => <DragHandle />
        },
        {
            title: "Название",
            dataIndex: 'name',
            key: 'name',
            width: 150,
            render: (_, record) => (
                <Input 
                    value={record.name}
                    required
                    onChange={(e) => {
                        state.statusTypes = state.statusTypes.map(st => {
                            if (st.id == record.id) return {...st, name: e.target.value}
                            return st;
                        });
                        statusTypeChange(record.id);
                    }}
                />
            )
        },
        {
            title: "Цвет",
            dataIndex: 'color',
            key: 'color',
            width: 50,
            render: (_, record) => (
                <ColorPicker 
                    disabledAlpha
                    value={record.color} 
                    onChange={(value) => {
                        state.statusTypes = state.statusTypes.map(st => {
                            if (st.id == record.id) return {...st, color: value.toHexString()}
                            return st;
                        });
                        statusTypeChange(record.id);
                    }}
                />
            )
        },
        {
            title: "",
            dataIndex: 'delete',
            key: 'delete',
            width: 40,
            render: (_, record) => (
                <Popconfirm
                    title="Удалить вид статуса"
                    description="Вид статуса будет удалён (совсем)"
                    onConfirm={() => {
                        rootStore.orderStatusStore.removeStatusType(record.id);
                    }}
                >
                    <Button danger icon={<FontAwesomeIcon icon={faTrashCan} />} />
                </Popconfirm>
            )
        }
    ];

    const onDragEnd = ({ active, over }: DragEndEvent) => {
        if (active.id !== over?.id) {
            const prev = Number(active.id);
            const prevId = state.statusTypes.find(st => st.order == prev)?.id;
            const cur = Number(over.id);
            if (cur > prev) {
                state.statusTypes = state.statusTypes.map((st) => {
                    if (st.order <= cur && st.id != prevId) {
                        statusTypeChange(st.id);
                        return {...st, order: st.order - 1};
                    } else if (st.id == prevId) {
                        statusTypeChange(st.id);
                        return {...st, order: cur};
                    }
                    else return st;
                }).sort((a, b) => a.order-b.order);
            } else {
                state.statusTypes = state.statusTypes.map((st) => {
                    if (st.order >= cur && st.id != prevId) {
                        statusTypeChange(st.id);
                        return {...st, order: st.order + 1};
                    } else if (st.id == prevId) {
                        statusTypeChange(st.id);
                        return {...st, order: cur};
                    }
                    else return st;
                }).sort((a, b) => a.order-b.order);
            }
        }
    };

    return (
        <div id="app-settings-list">
            <Row data-row-key="main">
                {state.isLoading && <Loader />}
                <HeaderBreadcrumbProfiles
                    title={'Настройка статусов заказа'}
                    dataTitle={'Настройки'}
                    dataIcon={faGear}
                    route={`/lk/worker/crm/${creds.crmID}/settings`}
                    dataName={sectionName}
                    dataId='Статусы'
                    isForInvitation={false}
                    isSpecialty={true}
                />
                <Row data-row-key={""}>
                    <Col className="order-status-setting-content">
                        <Input.Search
                            placeholder={'Введите название для поиска или создания статуса'}
                            onChange={handleSearch}
                            allowClear
                            value={state.searchValue}
                            className="order-status-setting-input"
                            loading={state.isSearching}
                        />
                    </Col>
                    <RenderIf condition={state.searchValue !== ''}>
                        <Col span={24} className="order-status-setting-btns">
                            <Row data-row-key="add" style={{ justifyContent: "space-between" }}>
                                <Col>
                                    <Button onClick={() => addStatusType()} type="primary">
                                        + Тип статуса
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </RenderIf>
                </Row>
                <Row data-row-key={""}>
                    <Col>
                        <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
                            <SortableContext items={state.statusTypes.map((i) => i.order)} strategy={verticalListSortingStrategy}>
                                <Table
                                    style={{ marginBottom: '150px' }}
                                    pagination={false}
                                    bordered
                                    sticky
                                    columns={columns}
                                    dataSource={state.statusTypes ?? []}
                                    scroll={{ x: 'max-content' }}
                                    size={'small'}
                                    rowKey='order'
                                    components={{ body: { row: Row }}}
                                />
                            </SortableContext>
                        </DndContext>
                    </Col>
                </Row>
                <RenderIf condition={state.isFunctionalMenuVisible}>
                    <FunctionalMenu
                        items={[
                            {
                                key: 'save',
                                label: 'Сохранить',
                                icon: <FontAwesomeIcon icon={faCheck} />,
                                onClick: handleSave,
                            },
                        ]}
                        dropdownItems={[]}
                    />
                </RenderIf>
                {contextHolder}
            </Row>
        </div>
    );
});

export { OrderStatusSetting };