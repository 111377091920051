import { makeAutoObservable } from "mobx";
import { Moment } from "moment";
import { defaultRecords, defaultWorkers } from "./data";

type APRecord = {
    id: number;
    workerId: number;
    type: 'task' | 'service' | 'product';
    name: string;
    start: Moment;
    duration: number;
    amount: number;
    price: number;
    index: number;
};

type APWorker = {
    id: number;
    name: string;
    color: string;
};

class OrderAdminPanelStore {
    private records: APRecord[] = [];
    private workers: APWorker[] = [];

    constructor() {
        makeAutoObservable(this);
        this.records = defaultRecords;
        this.workers = defaultWorkers;
    }

    getWorkers() {
        return this.workers;
    }

    getWorker(id: number) {
        return this.workers.find(w => w.id == id);
    }

    getRecords(showServices: boolean, showTasks: boolean, showProducts: boolean, workerId?: number) {
        return this.records.filter(r => {
            if ((!showServices && r.type == 'service')
                || (!showTasks && r.type == 'task')
                || (!showProducts && r.type == 'product')
                || (workerId != null && r.workerId != workerId)
            ) {
                return false;
            }
            return true;
        }).sort((a, b) => a.index - b.index);
    }

    updateRecord(rec: APRecord) {
        this.records = this.records.map(r => {
            if (r.id == rec.id) {
                return {
                    ...r, 
                    workerId: rec.workerId,
                    name: rec.name, 
                    start: rec.start,
                    amount: rec.amount, 
                    price: rec.price,
                    index: rec.index
                };
            }
            return r;
        });
    }

    prepareDataSource(showServices: boolean, showTasks: boolean, showProducts: boolean) {
        const months = ["января", "февраля", "марта", "апреля", "мая", "июня", "июля", "августа", "сентября", "октября", "ноября", "декабря"];
        let recordIndex = 1;
        let ds = [];
        const tWorkers = this.getWorkers();
        for (let i = 0; i < tWorkers.length; i++) {
            ds.push({...tWorkers[i], case: "worker"});
            const tRecords = this.getRecords(showServices, showTasks, showProducts, tWorkers[i].id);
            let totalAmount = 0;
            let totalPrice = 0;
            for (let j = 0; j < tRecords.length; j++) {
                totalAmount += tRecords[j].amount;
                totalPrice += tRecords[j].amount * tRecords[j].price;
                if (j > 0 && tRecords[j].start.dayOfYear() > tRecords[j-1].start.dayOfYear()) {
                    ds.push({ 
                        case: "divider", 
                        divider: `${tRecords[j].start.date()} ${months[tRecords[j].start.month()]} ${tRecords[j].start.year()}`
                    });
                }
                ds.push({...tRecords[j], case: "record", index: recordIndex});
                recordIndex++;
            }
            ds.push({ case: "total", totalAmount: totalAmount, totalPrice: totalPrice });
        }
        return ds.map((v, i) => {return {...v, order: i}});
    }
}

export { APRecord, APWorker, OrderAdminPanelStore };