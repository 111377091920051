import { useReactive, useToggle } from "ahooks";
import { TTask, TTaskCategory } from "../common/types";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useRef } from "react";
import { messageService } from "@classes/messageService";
import { SettingsManager } from "@classes/settingsManager";
import { makeEmptyTask } from "../common/data";
import { CRMAPIManager } from "@classes/crmApiManager";
import { Loader } from "@atoms/loader";
import { FunctionalMenu } from "@molecules/functionalMenu";
import { HeaderBreadcrumbProfiles } from "@molecules/breadcrumbs/profilesBreadcrumbs/HeaderProfilesBreadcrumb";
import { faCheck, faEdit, faKeyboard, faMicrophone, faPause, faPersonRunning, faStop, faXmark } from "@fortawesome/free-solid-svg-icons";
import { Button, Col, Input, Popconfirm, Row, Space } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TaskResp } from "@api/responseModels/task/taskResponse";
import TextArea from "antd/lib/input/TextArea";
import { DurationPicker } from "@organisms/pickers/durationPicker";
import { Common } from "@classes/commonMethods";
import "./style.scss";
import { ImgUploader } from "@organisms/imgUploader/imgUploader";
import { ServicePicker } from "@organisms/pickers/servicePicker";
import { ProductPicker } from "@organisms/pickers/productPicker";
import RenderIf from "@common/RenderIf";
import { FileThumbnail } from "@organisms/orders/profile/components/orderComment/common/components/fileThumbnail";
import useStopwatch from "@organisms/orders/profile/common/hooks/useStopwatch";
import { FileType } from "@organisms/orders/profile/components/orderComment/common/types";
import { TaskCategoryPicker } from "@organisms/pickers/taskCategoryPicker";
import { LastIdStore } from "@pages/lastIdStore";

const microUsingStartedMp3Link = 'https://free-sound-effects.net/mp3/03/free-sound-1674743345.mp3';

export enum TaskUseCases {
    Profile = 'profile',
    Edit = 'edit',
    Create = 'create',
};

type TProps = {
    useCase?: TaskUseCases 
};

type TState = {
    isLoading: boolean;
    isDurationPickerVisible: boolean;
    isProductPickerVisible: boolean;
    isServicePickerVisible: boolean;
    isTaskCategoryPickerVisible: boolean;
    case: TaskUseCases;
    task?: TTask & { category: TTaskCategory };
    mediaStream?: any;
    recordingTarget?: 'task_statement' | 'description';
    isPaused: boolean;
};

const TaskProfile = ({ useCase }: TProps): JSX.Element => {
    const state = useReactive<TState>({
        isLoading: false,
        isDurationPickerVisible: false,
        isProductPickerVisible: false,
        isServicePickerVisible: false,
        isTaskCategoryPickerVisible: false,
        task: null,
        case: useCase ?? TaskUseCases.Profile,
        mediaStream: null,
        recordingTarget: null,
        isPaused: false,
    });
    const [isRecording, { toggle: toggleIsRecording }] = useToggle(false);
    const { minutes, seconds } = useStopwatch({
        isRunning: isRecording,
        isPaused: state.isPaused,
    });
    let { taskID } = useParams();
    const navigate = useNavigate();
    const vibrate = navigator.vibrate;
    const mediaRecorder = useRef(null);
    const creds = SettingsManager.getConnectionCredentials();

    function validate(): boolean {
        if (state.task.name == '' || state.task.name === null) {
            messageService.sendError('Имя задачи не может быть пустым');
            return false;
        }
        if (state.task.duration == null || state.task.duration < 0) {
            messageService.sendError('Продолжительность должна быть задана');
            return false;
        }
        if (state.task.duration != null && state.task.duration > 518400) {
            messageService.sendError('Продолжительность должна быть меньше 12 месяцев');
            return false; // константа по макс. доступной крутилке (12 мес)
        }
        if (
            state.task.reward == null ||
            state.task.reward <= 0 ||
            state.task.reward > 999999.99
        ) {
            messageService.sendError('На баланс исполнителя от 0 до 999 999.99 руб');
            return false;
        }
        if (
            state.task.min_performers == null ||
            state.task.min_performers < 1 ||
            state.task.min_performers > 10
        ) {
            messageService.sendError('Количество исполнителей должно быть от 1 до 10');
            return false;
        }
        if (isRecording) return false;
        return true;
    }

    function handleEdit() {
        state.case = TaskUseCases.Edit;
        navigate(`/lk/worker/crm/${creds.crmID}/tasks/${taskID}/edit`);
    }

    async function handleSave() {
        if (!validate()) return;
        state.isLoading = true;
        try {
            const response = await CRMAPIManager.request<TaskResp>(async (api) => {
                if (state.case == TaskUseCases.Create) {
                    return await api.saveTask(state.task);
                }
                else {
                    return await api.updateTask(state.task);
                }
            });
            if (response.errorMessages) throw response.errorMessages;
            state.task = response.data.data;
            state.case = TaskUseCases.Profile;
            navigate(`/lk/worker/crm/${creds.crmID}/tasks/${response.data.data.id}`);
        } catch (errors) {
            messageService.sendErrorList(errors);
        }
        state.isLoading = false;
    }

    async function handleCancel() {
        if (isRecording) stopRecording({ preventDefault: () => null });
        if (state.case == TaskUseCases.Create) navigate(`/lk/worker/crm/${creds.crmID}/tasks`);
        else {
            state.case = TaskUseCases.Profile;
            navigate(`/lk/worker/crm/${creds.crmID}/tasks/${taskID}`);
            await loadExistingTask();
        }
    }

    function setEmptyTask() {
        state.task = makeEmptyTask(creds.crmID, LastIdStore.lastTaskCategoryId);
    }

    async function loadExistingTask() {
        state.isLoading = true;
        try {
            const response = await CRMAPIManager.request<TaskResp>(async (api) => {
                return await api.getTask(creds.crmID, Number(taskID));
            });
            if (response.errorMessages) throw response.errorMessages;
            state.task = response.data.data;
        } catch (errors) {
            messageService.sendErrorList(errors);
        }
        state.isLoading = false;
    }

    function handleImageSelect(file: File) {
        state.task.picture = file;
        state.task.picture["src"] = URL.createObjectURL(state.task.picture);
    }

    function handleImageDelete() {
        state.task.picture = '/images/no_image.png';
    }

    async function initializeTask() {
        if (state.case == TaskUseCases.Create) setEmptyTask();
        else await loadExistingTask();
    }

    const vibrateDevice = () => {
        if (!vibrate) return;
        vibrate.call(navigator, [100]);
    };

    const startRecording = (e, target: 'task_statement' | 'description') => {
        e.preventDefault();
        if (isRecording) return;
        vibrateDevice();
        navigator.mediaDevices
            .getUserMedia({ audio: true })
            .then((stream) => {
                const audio = new Audio(microUsingStartedMp3Link);
                audio.volume = 0.5;
                audio.play();
                state.mediaStream = stream;
                const mediaRecorderInstance = new MediaRecorder(stream);
                mediaRecorderInstance.ondataavailable = (e) => {
                    if (e.data.size > 0) {
                        const name = target == 'task_statement' ? 'Постановка' : 'Описание';

                        const blob = new Blob([e.data], { type: 'audio/wav' });
                        const id = Math.floor(Math.random() * 9000) + 1000;
                        state.task[target] = {
                            id,
                            name,
                            extension: 'audio',
                            blob,
                        };
                    }
                };
                mediaRecorderInstance.start();
                mediaRecorder.current = mediaRecorderInstance;
                state.recordingTarget = target;
                toggleIsRecording();
            })
            .catch(console.error);
    };

    const pauseRecording = (e) => {
        e.preventDefault();
        if (!mediaRecorder.current && state.isPaused) return;
        mediaRecorder.current.pause();
        state.isPaused = true;
    };

    const resumeRecording = (e) => {
        e.preventDefault();
        if (!mediaRecorder.current && !state.isPaused) {
            return;
        }
        mediaRecorder.current.resume();
        state.isPaused = false;
    };

    const stopRecording = (e) => {
        e.preventDefault();
        if (!mediaRecorder.current && !isRecording) return;

        mediaRecorder.current.stop();
        toggleIsRecording();
        state.recordingTarget = null;

        if (state.mediaStream) {
            state.mediaStream.getTracks().forEach((track) => {
                track.stop();
            });
            state.mediaStream = null;
        }
        vibrateDevice();
        state.isPaused = false;
    };

    useEffect(() => {
        initializeTask();
    }, []);

    return (
        <div className="profile-template task-profile">
            {state.isLoading && <Loader />}
            {state.isDurationPickerVisible && (
                <DurationPicker
                    isModalOpen={state.isDurationPickerVisible}
                    onCancel={() => { state.isDurationPickerVisible = false; }}
                    defaultValue={state.task?.duration}
                    totalTime={state.task?.total_time}
                    onOk={(duration, total_time) => {
                        state.task.duration = duration;
                        state.task.total_time = total_time;
                        state.isDurationPickerVisible = false;
                    }}
                />
            )}
            {state.isProductPickerVisible && (
                <ProductPicker 
                    isModalOpen={state.isProductPickerVisible}
                    setIsModalOpen={(isOpen) => state.isProductPickerVisible = isOpen}
                    multiple={true}
                    selectedByDefault={state.task?.products}
                    setSelected={(selected) => state.task.products = selected}
                />
            )}
            {state.isServicePickerVisible && (
                <ServicePicker 
                    isModalOpen={state.isServicePickerVisible}
                    setIsModalOpen={(isOpen) => state.isServicePickerVisible = isOpen}
                    multiple={true}
                    selectedByDefault={state.task?.services}
                    setSelected={(selected) => state.task.services = selected}
                />
            )}
            {state.isTaskCategoryPickerVisible && (
                <TaskCategoryPicker
                    isModalOpen={state.isTaskCategoryPickerVisible}
                    setIsModalOpen={(isOpen) => state.isTaskCategoryPickerVisible = isOpen}
                    multiple={false}
                    selectedByDefault={state.task?.task_category_id == null ? [] : [{ id: state.task?.task_category_id, name: state.task?.category?.name, parentId: state.task?.category?.parent_task_category_id }]}
                    setSelected={(selected) => {
                        if (selected.length > 0) state.task.task_category_id = selected[0].id;
                        else state.task.task_category_id = null;
                    }}
                />
            )}
            <FunctionalMenu
                items={state.case != TaskUseCases.Profile
                    ? [
                        {
                            key: 'save',
                            label: state.case == TaskUseCases.Create ? 'Создать' : 'Сохранить',
                            icon: <FontAwesomeIcon icon={faCheck} />,
                            onClick: handleSave,
                        },
                        {
                            key: 'abort',
                            label: 'Отменить',
                            icon: <FontAwesomeIcon icon={faXmark} />,
                            onClick: handleCancel,
                        },
                    ] : [
                        {
                            key: 'edit',
                            label: 'Редактировать',
                            icon: <FontAwesomeIcon icon={faEdit} />,
                            onClick: handleEdit,
                        },
                    ]}
                dropdownItems={[]}
            />
            <HeaderBreadcrumbProfiles
                dataIcon={faPersonRunning}
                dataId={state.task?.id}
                dataTitle={'Задачи'}
                title={'Профиль задачи'}
                route={`/lk/worker/crm/${creds.crmID}/tasks`}
                isForInvitation={false}
                dataName={'Новая задача'}
                isSpecialty={false}
            />
            <div className="profile-inner">
                <Row className="profile-row">
                    <Col className="avatar outer-box" flex={1}>
                        <ImgUploader
                            photoSrc={typeof state.task?.picture == 'string' ? state.task?.picture as string : state.task?.picture["src"]}
                            onImageSelect={handleImageSelect}
                            isEdit={state.case != TaskUseCases.Profile}
                            onImageDelete={handleImageDelete}
                        />
                        
                    </Col>
                    <Col className="outer-box" flex={1}>
                        <Row className="label-row">
                            <Col>
                                <p>Название</p>
                            </Col>
                        </Row>
                        <Row className="value-row">
                            <Col>
                                {state.case != TaskUseCases.Profile ? (
                                    <TextArea
                                        autoSize={{ minRows: 2, maxRows: 5 }}
                                        value={state.task?.name}
                                        onChange={(e) => {
                                            state.task.name = e.target.value;
                                        }}
                                    />
                                ) : (
                                    <p>{state.task?.name}</p>
                                )}
                            </Col>
                        </Row>
                    </Col>
                    <Col className="outer-box" flex={1}>
                        <Row className="label-row">
                            <Col>
                                <p>Продолжительность</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {state.case != TaskUseCases.Profile && (
                                    <Button
                                        type="primary"
                                        shape="circle"
                                        icon={<FontAwesomeIcon icon={faEdit} />}
                                        onClick={() => {state.isDurationPickerVisible = true;}}
                                    />
                                )}
                            </Col>
                        </Row>
                        <Row className="value-row">
                            <Col>
                                <p>
                                    {state.task?.duration == 0
                                        ? 'Не задана'
                                        : Common.convertMinutes2Date(state.task?.duration)}
                                </p>
                                <p>
                                    Учитывается
                                    {state.task?.total_time
                                        ? ' общее время'
                                        : ' только рабочее время'}
                                </p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="profile-row">
                    <Col className="outer-box" flex={1}>
                        <Row className="label-row">
                            <Col>
                                <p>На баланс исполнителя</p>
                            </Col>
                        </Row>
                        <Row className="value-row">
                            <Col>
                                {state.case != TaskUseCases.Profile ? (
                                    <Input
                                        type="number"
                                        inputMode="numeric"
                                        min={0}
                                        max={999999}
                                        step={0.01}
                                        value={state.task?.reward}
                                        onChange={(e) => {
                                            state.task.reward = Number.parseFloat(e.target.value);
                                        }}
                                    />
                                ) : (
                                    <p>{state.task?.reward == 0 ? 'Не задано' : state.task?.reward + ' руб'}</p>
                                )}
                            </Col>
                        </Row>
                    </Col>
                    <Col className="outer-box" flex={1}>
                        <Row className="label-row">
                            <Col>
                                <p>Группа</p>
                            </Col>
                        </Row>
                        <Row
                            className="value-row"
                            onClick={() => {
                                if (state.case != TaskUseCases.Profile) state.isTaskCategoryPickerVisible = true;
                            }}
                        >
                            <Col>
                                {state.case != TaskUseCases.Profile ? (
                                    <a>
                                        {state.task?.task_category_id ??
                                            state.task?.category?.name ??
                                            'Не выбрана'}
                                    </a>
                                ) : (
                                    <p>{state.task?.category?.name ?? 'Не выбрана'}</p>
                                )}
                            </Col>
                        </Row>
                    </Col>
                    <Col className="outer-box" flex={1}>
                        <Row className="label-row">
                            <Col>
                                <p>Соп. товары</p>
                            </Col>
                        </Row>
                        <Row 
                            className="value-row"
                            onClick={() => {
                                if (state.case != TaskUseCases.Profile) state.isProductPickerVisible = true;
                            }}
                        >
                            <Col>
                                { state.case == TaskUseCases.Profile 
                                    ? (<p>{state.task?.products.length}</p>)
                                    : (<a>{state.task?.products.length}</a>)
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="profile-row">
                    <Col className="outer-box" flex={1}>
                        <Row className="label-row">
                            <Col>
                                <p>Соп. услуги</p>
                            </Col>
                        </Row>
                        <Row 
                            className="value-row"
                            onClick={() => {
                                if (state.case != TaskUseCases.Profile) state.isServicePickerVisible = true;
                            }}
                        >
                            <Col>
                                { state.case == TaskUseCases.Profile 
                                    ? (<p>{state.task?.services.length}</p>)
                                    : (<a>{state.task?.services.length}</a>)
                                }
                            </Col>
                        </Row>
                    </Col>
                    <Col className="outer-box" flex={1}>
                        <Row className="label-row">
                            <Col>
                                <p>Мин. исполнителей</p>
                            </Col>
                        </Row>
                        <Row className="value-row">
                            <Col>
                                {state.case != TaskUseCases.Profile ? (
                                    <Input
                                        type="number"
                                        inputMode="numeric"
                                        min={1}
                                        max={10}
                                        step={1}
                                        value={state.task?.min_performers}
                                        onChange={(e) => {
                                            state.task.min_performers = Number.parseInt(
                                                e.target.value
                                            );
                                        }}
                                    />
                                ) : (
                                    <p>{state.task?.min_performers}</p>
                                )}
                            </Col>
                        </Row>
                    </Col>
                    <Col className="outer-box" flex={1}>
                        <Row className="label-row">
                            <Col>
                                <p>Прослушать задачу</p>
                            </Col>
                        </Row>
                        <Row className="value-row">
                            <Col>
                                <RenderIf condition={state.case == TaskUseCases.Profile}>
                                    <RenderIf condition={state.task?.task_statement == null}>
                                        <p>Не задано</p>
                                    </RenderIf>
                                    <RenderIf condition={state.task?.task_statement != null}>
                                        <FileThumbnail
                                            index={0}
                                            files={[state.task?.task_statement]}
                                            file={state.task?.task_statement}
                                            key={state.task?.task_statement?.id}
                                        />
                                    </RenderIf>
                                </RenderIf>
                                <RenderIf condition={state.case != TaskUseCases.Profile}>
                                    <RenderIf condition={state.task?.task_statement != null}>
                                        <FileThumbnail
                                            index={0}
                                            files={[state.task?.task_statement]}
                                            file={state.task?.task_statement}
                                            key={state.task?.task_statement?.id}
                                            removeFile={(_) => state.task.task_statement = null}
                                        />
                                    </RenderIf>
                                    <RenderIf condition={state.task?.task_statement == null}>
                                        <RenderIf condition={isRecording && state.recordingTarget == 'task_statement'}>
                                            <Row
                                                className="comment-block"
                                                align={'middle'}
                                            >
                                                <Col>
                                                    <div className="stopwatch-display">
                                                        <h3>Запись аудио</h3>
                                                        <h2>
                                                            {minutes}:{seconds}
                                                        </h2>
                                                    </div>
                                                </Col>
                                                <Col className="voice-btn">
                                                    <Space direction="vertical">
                                                        <RenderIf condition={!state.isPaused}>
                                                            <Button
                                                                onClick={pauseRecording}
                                                                icon={
                                                                    <FontAwesomeIcon
                                                                        icon={faPause}
                                                                        color="orange"
                                                                        size="lg"
                                                                    />
                                                                }
                                                            ></Button>
                                                        </RenderIf>
                                                        <RenderIf condition={state.isPaused}>
                                                            <Button
                                                                onClick={resumeRecording}
                                                                icon={
                                                                    <FontAwesomeIcon
                                                                        icon={faMicrophone}
                                                                        color="orange"
                                                                        size="lg"
                                                                    />
                                                                }
                                                            ></Button>
                                                        </RenderIf>
                                                        <Button
                                                            onClick={stopRecording}
                                                            icon={
                                                                <FontAwesomeIcon icon={faStop} color="orange" size="lg" />
                                                            }
                                                        ></Button>
                                                    </Space>
                                                </Col>
                                            </Row>
                                        </RenderIf>
                                        <RenderIf condition={!isRecording || (isRecording && state.recordingTarget == 'description')}>
                                            <Button
                                                onClick={(e) => startRecording(e, 'task_statement')}
                                                shape="circle"
                                                className="comment-type-select"
                                            >
                                                <span className="comment-type-select">+</span>
                                                {<FontAwesomeIcon icon={faMicrophone} color="orange" size="lg" />}
                                            </Button>
                                        </RenderIf>
                                    </RenderIf>
                                </RenderIf>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="profile-wide outer-box description-tab">
                    <Col>
                        <Row className="label-row">
                            <Col>
                                <p>Суть задачи, описание</p>
                            </Col>
                            <RenderIf condition={state.case != TaskUseCases.Profile}>
                                <Col>
                                    <Popconfirm
                                        title="Смена типа описания - введённые данные удалятся"
                                        onConfirm={() => {
                                            if (state.task?.description_type == 'text') state.task.description_type = 'voice';
                                            else state.task.description_type = 'text';
                                            state.task.description = null;
                                        }}
                                        okText="Да"
                                        cancelText="Нет"
                                        disabled={state.task?.description == null || state.task?.description == ""}
                                    >
                                        <Button
                                            className="description-type-select"
                                            icon={<FontAwesomeIcon icon={state.task?.description_type == 'text' ? faKeyboard : faMicrophone} />}
                                            onClick={() => {
                                                if (state.task?.description == null || state.task?.description == "") {
                                                    if (state.task?.description_type == 'text') state.task.description_type = 'voice';
                                                    else state.task.description_type = 'text';
                                                    state.task.description = null;
                                                }
                                            }}
                                            shape="circle"
                                        />
                                    </Popconfirm>
                                </Col>
                            </RenderIf>
                        </Row>
                        <Row className="value-row">
                            <Col>
                                <RenderIf condition={state.case == TaskUseCases.Profile}>
                                    <RenderIf condition={state.task?.description_type == 'text'}>
                                        <p>{state.task?.description as string}</p>
                                    </RenderIf>
                                    <RenderIf condition={state.task?.description_type == 'voice'}>
                                        {state.task?.description == null ? (
                                            <p>Не задано</p>
                                        ) : (
                                            <FileThumbnail
                                                index={0}
                                                files={[state.task?.description as FileType]}
                                                file={state.task?.description as FileType}
                                                key={(state.task?.description as FileType)?.id}
                                            />
                                        )}
                                    </RenderIf>
                                </RenderIf>
                                <RenderIf condition={state.case != TaskUseCases.Profile}>
                                    <RenderIf condition={state.task?.description_type == 'text'}>
                                        <TextArea
                                            autoSize={{ minRows: 2, maxRows: 5 }}
                                            maxLength={255}
                                            onChange={(e) => {
                                                state.task.description = e.target.value;
                                            }}
                                            value={state.task?.description as string}
                                        />
                                    </RenderIf>
                                    <RenderIf condition={state.task?.description_type == 'voice'}>
                                        <RenderIf condition={isRecording && state.recordingTarget == 'description'}>
                                            <Row
                                                className="comment-block"
                                                align={'middle'}
                                            >
                                                <Col>
                                                    <div className="stopwatch-display">
                                                        <h3>Запись аудио</h3>
                                                        <h2>
                                                            {minutes}:{seconds}
                                                        </h2>
                                                    </div>
                                                </Col>
                                                <Col className="voice-btn">
                                                    <Space direction="vertical">
                                                        <RenderIf condition={!state.isPaused}>
                                                            <Button
                                                                onClick={pauseRecording}
                                                                icon={
                                                                    <FontAwesomeIcon
                                                                        icon={faPause}
                                                                        color="orange"
                                                                        size="lg"
                                                                    />
                                                                }
                                                            ></Button>
                                                        </RenderIf>
                                                        <RenderIf condition={state.isPaused}>
                                                            <Button
                                                                onClick={resumeRecording}
                                                                icon={
                                                                    <FontAwesomeIcon
                                                                        icon={faMicrophone}
                                                                        color="orange"
                                                                        size="lg"
                                                                    />
                                                                }
                                                            ></Button>
                                                        </RenderIf>
                                                        <Button
                                                            onClick={stopRecording}
                                                            icon={
                                                                <FontAwesomeIcon icon={faStop} color="orange" size="lg" />
                                                            }
                                                        ></Button>
                                                    </Space>
                                                </Col>
                                            </Row>
                                        </RenderIf>
                                        <RenderIf condition={!isRecording || (isRecording && state.recordingTarget == 'task_statement')}>
                                            <RenderIf condition={state.task?.description == null}>
                                                <Button
                                                    onClick={(e) => startRecording(e, 'description')}
                                                    shape="circle"
                                                    className="comment-type-select"
                                                >
                                                    <span className="comment-type-select">+</span>
                                                    {<FontAwesomeIcon icon={faMicrophone} color="orange" size="lg" />}
                                                </Button>
                                            </RenderIf>
                                            <RenderIf condition={state.task?.description != null}>
                                                <FileThumbnail
                                                    index={0}
                                                    files={[state.task?.description as FileType]}
                                                    file={state.task?.description as FileType}
                                                    key={(state.task?.description as FileType)?.id}
                                                    removeFile={(_) => state.task.description = null}
                                                />
                                            </RenderIf>
                                        </RenderIf>
                                    </RenderIf>
                                </RenderIf>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default TaskProfile;