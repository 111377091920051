import { faBarcode, faPlusCircle, faScrewdriverWrench, faThumbTack } from '@fortawesome/free-solid-svg-icons';

export const TOPIC_CATEGORIES = {
    PRODUCT: 'product',
    SERVICE: 'service',
    CUSTOM: 'custom',
    STATUS: 'status'
};

export const TOPIC_ICONS = {
    [TOPIC_CATEGORIES.PRODUCT]: faBarcode,
    [TOPIC_CATEGORIES.SERVICE]: faScrewdriverWrench,
    [TOPIC_CATEGORIES.CUSTOM]: faPlusCircle,
    [TOPIC_CATEGORIES.STATUS]: faThumbTack
};
